@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    /* text-align: center; */
    background-color: #282c34;
}

.pixel-font {
    font-family: "Press Start 2P";
}
.nunito-font {
    font-family: "Nunito";
}
.section {
    margin: auto;
    width: 80vw;
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding-top: 2rem;
}
.navbar-toggler {
    background-color: white !important;
}
.game {
    font-family: "Press Start 2P";
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.display-img {
    width: 100%;
    padding-bottom: 2rem;
}
.snapshot {
    width: 80vw;
    margin: auto;
}
.team-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.card-team {
    width: 100%;
    padding-bottom: 10px;
}
.info-team {
    width: 100%;
}

.line {
    width: 100%;
    height: 10px;
    -webkit-animation: animateBg 7s linear infinite;
            animation: animateBg 7s linear infinite;
    background-image: linear-gradient(
        270deg,
        #f5f10a 25.28%,
        #098edb 59.7%,
        #d7f722
    );
    background-size: 1000% 1000%;
}
@media screen and (min-width: 800px) {
    .team-content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .card-team {
        width: 25%;
    }
    .info-team {
        width: 65%;
    }
    .snapshot {
        width: 50vw;
        margin: auto;
    }
}

@-webkit-keyframes animateBg {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

@keyframes animateBg {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

